import React from 'react'
import { useTranslation } from 'react-i18next'

const BackButton = (props) => {
  const { t } = useTranslation()

  const handleClick = () => {
    props.onChangeComponentState()
  }

  return <div
    data-testid="back-button"
    className={`text-blue-800 flex items-center justify-start absolute top-0 -left-2 sm:-left-6 z-20 text-base sm:text-xl cursor-pointer`}
    onClick={() => handleClick()}>
    <i className="icon-caret-left text-base sm:text-2xl"></i>
    <div>{t('back_button.label')}</div>
  </div>
}

export default BackButton
