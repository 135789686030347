import React, {useEffect} from 'react'
import {removeCookie} from '../tools/cookie.js'

const Debug = (props) => {

  const handleExitButtonClick = () => {
    props.onChangeComponentState('getaccess')
  }

  const handleResetButtonClick = () => {
    localStorage.clear()
    removeCookie('configBearerToken')
    removeCookie('personalBearerToken')
    window.location.reload()
  }

  useEffect(() => {
    document.documentElement.classList.add("bg-green")
    return () => document.documentElement.classList.remove("bg-green")
  })

  return <div className="pt-20">

    <p className="mb-12 flex items-center">
      <span className="text-4xl font-bold text-white">Debug Menu</span>
      <span className="ml-6 text-lg border border-black rounded py-2 px-6" onClick={() => handleExitButtonClick()}>Exit</span>
    </p>

    <table className="text-lg" cellPadding="5">
      <tbody>
        <tr>
          <td><strong>App Version</strong></td>
          <td><code>1.2</code></td>
        </tr>
        <tr>
          <td><strong>Name</strong></td>
          <td><code>{props.config.name}</code></td>
        </tr>
        <tr>
          <td><strong>Host</strong></td>
          <td><code>{`${props.apiProtocol}${props.apiUrl}`}</code></td>
        </tr>
        <tr>
          <td><strong>Network</strong></td>
          <td>{navigator.onLine ? `Device is online 🙂` : `Device is offline 🙁`}</td>
        </tr>
        <tr>
          <td><strong>Screen Resolution</strong></td>
          <td><code>{window.screen.width} x {window.screen.height}</code></td>
        </tr>
      </tbody>
    </table>

    <p onClick={() => { window.confirm('Sure?') && (handleResetButtonClick()) }} className="inline-block mt-10 text-lg border border-black rounded py-2 px-6">
      🗑&nbsp;&nbsp;Reset Kiosk Config
    </p>

  </div>
}

export default Debug
