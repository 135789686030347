import React, { useState, useEffect } from 'react'
import ClientOAuth2 from 'client-oauth2'
import FullscreenStatus from '../components/FullscreenStatus'
import Keyboard from '../components/Keyboard'
import {setCookie} from '../tools/cookie.js'
import { useTranslation } from 'react-i18next'
import iconCrossRed from '../images/icon-cross-red.svg'
import timers from '../timers'

const Login = (props) => {
  const { t } = useTranslation()
  const [loginState, setLoginState] = useState("unset")
  const [typedInput, setTypedInput] = useState("")
  const [typedInputLength, setTypedInputLength] = useState(0)

  const auth = new ClientOAuth2({
    clientId: props.clientid,
    accessTokenUri: `${props.apiProtocol}${props.apiUrl}/oauth/token`,
    clientSecret: props.oAuthClientSecret,
    scopes: ['me'],
    headers: { "Sensorberg-Kiosk-Token": props.kioskClientToken}
  })

  const sendData = (identification_code) => {
    console.log(`%c[LOGIN] Submitted identification code: ${identification_code}`, 'border: 1px solid red')
    auth.owner.getToken('', identification_code)
      .then((data) => {
        setCookie('personalBearerToken',JSON.stringify(data.data), props.config.sessionBehaviour.logoutAfterLoginInSeconds)
        setLoginState("loading")
        window.setTimeout(() => { setLoginState("success") }, timers.loadingStateDuration )
        window.setTimeout(() => { props.onChangeComponentState("devices") }, timers.loadingStateDuration )
      })
      .catch(() => {
        setLoginState("loading")
        window.setTimeout(() => { setLoginState("error") }, timers.loadingStateDuration )
        window.setTimeout(() => {
          setLoginState("unset")
          setTypedInput("")
          setTypedInputLength(0)
        }, timers.errorStateDuration )
      })
  }

  const handleSubmit = () => {
    if (typedInput.length > 0) {
      typedInput === process.env.REACT_APP_DEBUG_PIN ?
        props.onChangeComponentState("debug") :
        sendData(typedInput)
    }
  }

  useEffect(() => {
    const captureKey = (key, keyCode) => {

      // get current input length since setState is asynchronous
      let length = typedInput.length

      if ((key >= 0 && key <= 9) || ((keyCode >= 65) && (keyCode <= 90))) {
        setTypedInput(typedInput + key)
        setTypedInputLength(typedInputLength + 1)
        length++
      }

      if (((key === "x") || (key === "Backspace")) && (length > 0)) {
        setTypedInput(typedInput.slice(0, -1))
        setTypedInputLength(typedInputLength - 1)
        length--
      }

      key === "Enter" && sendData(typedInput)
      length === 0 ? setLoginState("unset") : setLoginState("password_given")
    }

    const captureHardwareKey = (event) => { captureKey(event.key, event.keyCode) }

    document.querySelectorAll("[data-behavior~=captureKey]").forEach((element) => {
      element.addEventListener("click", (event) => {
        captureKey(event.target.dataset.value, null)
      })
    })

    document.addEventListener("keyup", captureHardwareKey)

    return () => {
      document.removeEventListener("keyup", captureHardwareKey)
      document.querySelectorAll("[data-behavior~=captureKey]").forEach((element) => { document.removeEventListener("click", element) })
    }
  })

  if (loginState === "loading") {
    return <FullscreenStatus icon="spinner" classes="bg-grey-200" text1={t('login.login_state_loading_text_1')} />
  }
  else if (loginState === "error") {
    return <FullscreenStatus icon={iconCrossRed} classes="bg-red-300" text1={t('login.login_state_error_text_1')} text2={t('login.login_state_error_text_2')} />
  }

  else if (props.method === "pin") {
    return <div data-testid="login-pin" className="pt-12 sm:pt-28">
      <p className="text-blue-800 text-lg sm:text-2xl font-bold">{t('login.pin_headline')}</p>
      <p className="text-blue-800 sm:text-xl inline-block portrait-xs:mb-6 landscape-xs:mb-16"> {t('login.pin_subheadline')} </p>
      <div className="flex flex-col items-center justify-center landscape-xs:flex-row">
        <div className="
          flex items-center justify-between
          portrait-xs:mb-8 portrait-sm:mb-16
          landscape-xs:mr-8 landscape-md:mr-16
          sm:text-red-500
          portrait-xs:w-71 portrait-xs:mx-auto
          portrait-sm:w-full portrait-sm:mx-auto
          portrait-md:w-128 portrait-md:mx-auto
          ">
          <div
            data-testid="faked-input"
            data-value={typedInput}
            className={`bg-white landscape-xs:w-78 shadow h-16 sm:h-24 max-w-md flex-1 rounded overflow-hidden px-3 sm:px-10 mr-3 sm:mr-6 flex items-center text-blue-300 sm:text-lg`}>
            <span className={`flex justify-end overflow-hidden`}>
              <span className="flex items-center">
                {typedInputLength === 0 && t("password_input.placeholder")}
                {Array.from(Array(typedInputLength).keys()).map((i) => <span key={i} className="bg-blue-800 flex-1 h-4 w-4 inline-block rounded-full mr-2" />)}
              </span>
            </span>
          </div>
          <div
            onClick={handleSubmit}
            className={`
              flex items-center justify-center h-16 sm:h-24
              text-white  text-2xl text-center
              shadow rounded
              py-5 px-4 sm:px-10 sm:w-32
              focus:outline-none
              ${loginState === "password_given" ? "bg-blue-800 cursor-pointer" : "bg-blue-400 cursor-default"}
            `}
            disabled
            >
              <i className="icon-arrow-right text-lg sm:text-2xl"></i>
          </div>
        </div>
        <Keyboard />
      </div>
    </div>
  }
  else if (props.method === "nfc") {
    return <div data-testid="login-nfc" className="pt-12 sm:pt-28 relative">
      <p className="text-lg sm:text-2xl text-blue-800 font-bold">{t('login.nfc_headline')}</p>
      <p className="text-blue-800 sm:text-xl inline-block mb-8"> {t('login.nfc_subheadline')} </p>
      <div className="w-full bg-blue-300 text-blue-800 rounded-lg p-4 sm:p-12 flex items-center justify-center flex-col">
        <div className="border-4 border-blue-200 rounded-lg p-4 portrait-sm:p-24 landscape-md:p-6 mb-4 sm:mb-12">
          <i className="icon-nfc-card-hand-at-sensor text-5xl landscape-md:text-5xl portrait-sm:text-7xl"></i>
        </div>
        <div className="sm:text-lg">{t("login.nfc_explanation")}</div>
      </div>
    </div>
  }
}

export default Login
