import { gql } from '@apollo/client'

const QueryData = gql`
  mutation requestActuatorAction($id: String!) {
    requestActuatorAction(
        actuatorId: $id
    ) {
      actingRequest {
        id
        state
      }
    }
  }
`
export default QueryData
