import React, {useState} from 'react'
import {Client} from './components/Client'
import Setup from './components/Setup'
import Debug from './components/Debug'
import Devices from './components/Devices'
import GetAccess from './components/GetAccess'
import SetDefaultLanguage from './components/SetDefaultLanguage'
import GetConfig from './components/GetConfig'
import GetConfigAccessToken from './components/GetConfigAccessToken'
import * as Sentry from "@sentry/react"
import './i18n/config'
import {removeCookie} from './tools/cookie.js'
import SecureLS from 'secure-ls'

window.onerror = (message) => Sentry.captureException(message)

const secureLocalStorage = new SecureLS({encodingType: 'aes', encryptionSecret: process.env.REACT_APP_LOCALSTORAGE_ENCRYPTION_KEY})

// do not show console.logs on production
if (process.env.NODE_ENV === 'production') { console.log = () => {} }

// remove cookies if app was refreshed before auto-logout
removeCookie('personalBearerToken')
removeCookie('configBearerToken')

function App() {

  // set viewport height to be used in CSS to fix mobile Safari’s 100vh 'bug'
  if (typeof window.viewportHeight === "undefined") {
    window.viewportHeight = () => { document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`) }
    window.addEventListener('resize', window.viewportHeight)
    window.viewportHeight()
  }

  //if no config is present in the localstorage, show login screen
  let initialAppState = "setup"

  if (secureLocalStorage.get('sensorbergApiUrl')) {
    initialAppState = "getconfigaccesstoken"
  }

  const [appState, setAppState] = useState(initialAppState)

  // logout automatically after cookie expiration
  if (appState === "devices") {
    const logoutAfterLoginInSeconds = secureLocalStorage.get('config').sessionBehaviour.logoutAfterLoginInSeconds
    console.log(`%c[AUTO LOGOUT] Set timer to ${logoutAfterLoginInSeconds} seconds`, 'background-color: #8933df; color: white')

    window.logoutAfterLoginTimer = setTimeout(() => {
      setAppState("getconfigaccesstoken")
      removeCookie('personalBearerToken')
      console.log(`%c[AUTO LOGOUT] Triggered after waiting ${logoutAfterLoginInSeconds} seconds`, 'background-color: #8933df; color: white')
    }, logoutAfterLoginInSeconds*1000 )
  }

  if (appState !== "devices" && window.logoutAfterLoginTimer) {
    console.log("%c[AUTO LOGOUT] Timer cancelled", 'background-color: #8933df; color: white')
    clearTimeout(window.logoutAfterLoginTimer)
  }

  return (
    <React.StrictMode>

      {window !== window.parent &&
        <h1 className="fixed bottom-6 left-6 right-6 bg-pink text-white py-1 px-2 text-xs">
          Kiosk loaded in iFrame. Please do not enter sensitive data unless you are working in a test environment.
        </h1>
      }

      {appState === "setup" &&
        <Setup onChangeComponentState={(value) => setAppState(value)}
          secureLocalStorage={secureLocalStorage}
        />
      }

      {appState === "getconfigaccesstoken" &&
        <GetConfigAccessToken
          onChangeComponentState={(value) => setAppState(value)}
          apiProtocol={secureLocalStorage.get('sensorbergApiProtocol')}
          apiUrl={secureLocalStorage.get('sensorbergApiUrl')}
          oAuthClientSecret={secureLocalStorage.get('sensorbergOauthClientSecret')}
          kioskClientToken={secureLocalStorage.get('sensorbergKioskClientToken')}
          clientId={secureLocalStorage.get('sensorbergOauthClientId')}
        />
      }

      {appState === "getconfig" &&
        <Client
          useDevicesToken={false}
          apiProtocol={secureLocalStorage.get('sensorbergApiProtocol')}
          apiUrl={secureLocalStorage.get('sensorbergApiUrl')}
          config={secureLocalStorage.get('config')}>
          <GetConfig
            onChangeComponentState={(value) => setAppState(value)}
            secureLocalStorage={secureLocalStorage}
            kioskClientToken={secureLocalStorage.get('sensorbergKioskClientToken')}
          />
        </Client>
      }

      {appState === "getaccess" &&
        <div>
          <SetDefaultLanguage config={secureLocalStorage.get('config')} />
          <GetAccess
            onChangeComponentState={(value) => setAppState(value)}
            appState={appState}
            config={secureLocalStorage.get('config')}
            configBearerToken={secureLocalStorage.get('configBearerToken')}
            clientId={secureLocalStorage.get('sensorbergOauthClientId')}
            apiProtocol={secureLocalStorage.get('sensorbergApiProtocol')}
            apiUrl={secureLocalStorage.get('sensorbergApiUrl')}
            oAuthClientSecret={secureLocalStorage.get('sensorbergOauthClientSecret')}
            kioskClientToken={secureLocalStorage.get('sensorbergKioskClientToken')}
           />
        </div>
      }

      {appState === "devices" &&
        <Client
          useDevicesToken={true}
          apiProtocol={secureLocalStorage.get('sensorbergApiProtocol')}
          apiUrl={secureLocalStorage.get('sensorbergApiUrl')}
          config={secureLocalStorage.get('config')}
          >
          <Devices
            onChangeComponentState={(value) => setAppState(value)}
            config={secureLocalStorage.get('config')}
            kioskClientToken={secureLocalStorage.get('sensorbergKioskClientToken')}
          />
        </Client>
      }

      {appState === "debug" &&
        <Debug
          onChangeComponentState={(value) => setAppState(value)}
          config={secureLocalStorage.get('config')}
          apiProtocol={secureLocalStorage.get('sensorbergApiProtocol')}
          apiUrl={secureLocalStorage.get('sensorbergApiUrl')}
        />
      }

    </React.StrictMode>
  )
}

export default App
