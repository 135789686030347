import React, {useEffect} from 'react'
import configQuery from '../gql/config.js'
import {useQuery} from '@apollo/client'
import FullscreenSpinner from './FullscreenSpinner'

const GetConfig = (props) => {

  const {loading: configLoading, data: config} = useQuery(configQuery, {
    context: {
      headers: {'X-Sensorberg-Kiosk-Client-Token': props.kioskClientToken}
    }, fetchPolicy: "network-only"
  })

  useEffect(() => {
    if (!configLoading && config) {
      if (config.kiosk === null) {
        localStorage.clear()
        props.onChangeComponentState("setup")
      }
      else {
        props.secureLocalStorage.set('config', config.kiosk.config)
        console.log(`%c[CONFIG] Current config: ${JSON.stringify(config)}`, 'background: #444; color: #eee')
        props.onChangeComponentState("getaccess")
      }
    }
  }, [props, config, configLoading])

  return <FullscreenSpinner />
}

export default GetConfig
