import React, {useEffect} from 'react'
import i18next from 'i18next'

const SetDefaultLanguage = (props) => {
  useEffect(() => {
    i18next.changeLanguage(props.config.language.default)
  })
  return <div data-testid="default-language" data-language={props.config.language.default} />
}

export default SetDefaultLanguage
