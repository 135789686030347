import React, {useState, useEffect} from 'react'
import Login from './Login'
import LanguageButton from './LanguageButton'
import BackButton from './BackButton'
import { useTranslation } from 'react-i18next'

const GetAccess = (props) => {
  const { t } = useTranslation()

  const [configLoginMethodState, setConfigLoginMethod] = useState(false)
  const [userLoginMethod, setUserLoginMethod] = useState(false)
  const [realLoginMethod, setRealLoginMethod] = useState(false)

  useEffect(() => {
    const ConfigLoginMethodObject = Object.keys(props.config.loginMethods).length > 1 ? 'all' : props.config.loginMethods[0]
    setConfigLoginMethod(ConfigLoginMethodObject)
    setRealLoginMethod(userLoginMethod ? userLoginMethod : ConfigLoginMethodObject)
  }, [userLoginMethod, props.configBearerToken, props.config])

  const changeAppState = (value) => {
    props.onChangeComponentState(value)
  }

  const Button = (props) => {
    return <span className={`
        bg-white active:bg-blue-800
        text-blue-800 active:text-white
        py-4 px-6
        ${props.className}
        flex items-center
        shadow active:shadow-none
        rounded-lg
        cursor-pointer`} data-testid={props.testId} onClick={() => setUserLoginMethod(props.method)}>
      <i className={`${props.icon} mr-6 text-2xl sm:text-5xl`}></i>
      <span className="flex-1 sm:text-xl">
        {props.text}
      </span>
    </span>
  }

  return <div className="relative">
    {(
      (configLoginMethodState === "all" && realLoginMethod === "all") ||
      (configLoginMethodState !== "all" && realLoginMethod !== "all")
    ) &&
      <LanguageButton config={props.config} />
    }

    {(configLoginMethodState === "all" && realLoginMethod !== "all") &&
      <BackButton onChangeComponentState={() => setUserLoginMethod("all")} />
    }

    {realLoginMethod === "all" &&
      <div className="sm:pt-28">
        <h1 className="text-blue-800 text-lg sm:text-2xl font-bold pr-20 sm:pr-0">{t('get_access.welcome')}</h1>
        <p className="text-blue-800 sm:text-xl inline-block mb-8 sm:mb-20 pr-20 sm:pr-0">{t('get_access.subline')}</p>
        <Button text={t('get_access.button_pin')} testId="button-pin" className="mb-8 sm:mb-16" icon="icon-pin" method="pin" />
        <Button text={t('get_access.button_nfc')} testId="button-nfc" className="" icon="icon-nfc-card-hand" method="nfc" />
      </div>
    }

    {realLoginMethod === "pin" &&
      <div>
        <Login
          data-testid="login-pin"
          method="pin"
          clientid={props.clientId}
          onChangeComponentState={(value) => changeAppState(value)}
          apiProtocol={props.apiProtocol}
          apiUrl={props.apiUrl}
          oAuthClientSecret={props.oAuthClientSecret}
          kioskClientToken={props.kioskClientToken}
          config={props.config}
        />
      </div>
    }

    {realLoginMethod === "nfc" &&
      <div>
        <Login
          data-testid="login-nfc"
          method="nfc"
          clientid={props.clientId}
          onChangeComponentState={(value) => changeAppState(value)}
          apiProtocol={props.apiProtocol}
          apiUrl={props.apiUrl}
          oAuthClientSecret={props.oAuthClientSecret}
          kioskClientToken={props.kioskClientToken}
          config={props.config}
        />
      </div>
    }

  </div>
}

export default GetAccess
