// https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/
// https://www.apollographql.com/docs/react/v2/data/local-state/
// https://www.apollographql.com/docs/react/data/queries/#setting-a-fetch-policy

import React from 'react'
import { ApolloClient, createHttpLink, ApolloProvider, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from "@apollo/client/link/error"
import { getCookie } from '../tools/cookie.js'
import * as Sentry from "@sentry/react"

export const Client = ({useDevicesToken, apiUrl, apiProtocol, children, config}) => {
  const cache = new InMemoryCache()
  const httpLink = createHttpLink({ uri: `${apiProtocol}${apiUrl}/graphql` })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) => {
        console.log( `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}` )
        Sentry.captureException(`[kiosk ${config.name}] GraphQL Error, Message: ${message}, Location: ${locations}, Path: ${path}`)
      })
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      Sentry.captureException(`[kiosk ${config.name}] Network Error, Message ${networkError}`)
    }
  })


  const authLink = setContext((_, { headers }) => {
    const token = useDevicesToken ? JSON.parse(getCookie('personalBearerToken')) : JSON.parse(getCookie('configBearerToken'))
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.access_token}` : "",

      }
    }
  })

  const defaultOptions = {
    watchQuery: { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network', errorPolicy: 'ignore' },
    query: { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-and-network', errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' }
  }

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    defaultOptions
  })

  return (<ApolloProvider client={client}> {children} </ApolloProvider>)
}

