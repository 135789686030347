import React from 'react'

const Keyboard = () => {

  const keys = [
    ['1', '1'],
    ['2', '2'],
    ['3', '3'],
    ['4', '4'],
    ['5', '5'],
    ['6', '6'],
    ['7', '7'],
    ['8', '8'],
    ['9', '9'],
    ['*', 'icon-star'],
    ['0', '0'],
    ['x', 'icon-backspace']
  ]

  const Key = (props) => {
    return <div
      data-testid={props.testid} data-value={props.value[0]}
      className="
        justify-self-stretch flex items-center justify-center
        py-2 portrait-xs:py-2 portrait-sm:py-4
        text-lg sm:text-2xl font-bold
        bg-white text-blue-800
        active:bg-blue-800 active:text-white
        shadow rounded
        cursor-pointer
      "
      data-behavior="captureKey" >
      {props.value[1].length === 1 ?
        props.value[0] :
        <i data-value={props.value[0]} className={props.value[1]}></i> }
    </div>
  }

  return <div data-testid="keyboard" className="
    grid gap-3 sm:gap-6 grid-cols-3
    mx-auto
    landscape:w-104
    portrait-xs:w-71
    portrait-sm:w-104
    portrait-md:w-104
  ">
    {keys.map((key,i) => {
      const testId = `keyboard-key-${i}`
      return <Key testid={testId} key={i} value={key} />
    })}
  </div>
}

export default Keyboard
