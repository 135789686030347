import React from 'react'
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react"
import App from './App'
import './styles/tailwind.css'

Sentry.init({
  dsn: "https://63400fc0bd4e848baa31d27a65bc556b@sentry.sensorberg.io/13",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/.*\.sensorberg\.(com|io)/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('app')
const root = createRoot(container)
root.render(<App tab="home" />)

