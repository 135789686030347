import React, {useState} from 'react'
import {ReactSVG} from 'react-svg'
import i18next from 'i18next'
import BackButton from './BackButton'
import iconCheckGreen from '../images/icon-check-green.svg'
import { useTranslation } from 'react-i18next'
import timers from '../timers.js'

const LanguageButton = (props) => {
  const { t } = useTranslation()
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language)
  const [languageOverlayVisible, setLanguageOverlayVisible] = useState(false)
  const availableLanguages = props.config.language.availableLanguages

  i18next.on('languageChanged', function(language) {
    setCurrentLanguage(language)
  })

  const handleLanguageOverlayClick = () => {
    setLanguageOverlayVisible(true)
  }

  const handleLanguageClick = (newLanguage) => {
    i18next.changeLanguage(newLanguage)
    setCurrentLanguage(newLanguage)

    window.setTimeout(() => {
      setLanguageOverlayVisible(false)
    }, timers.asyncOffsetDuration)
  }

  const handleBackLink = () => {
    setLanguageOverlayVisible(false)
  }

  return <div>
    <div
      data-testid="language-overlay"
      className={`
        absolute -top-4 sm:-top-12 -left-6 -right-6 z-30
        px-6 pt-4 sm:pt-12
        min-h-screen
        bg-grey-300 rounded
        ${!languageOverlayVisible && " hidden"}
      `}>

      <div className="relative pt-28">
        <BackButton onChangeComponentState={() => {handleBackLink()}} />

        <h1 className="text-blue-800 text-2xl font-bold">{t('language_button.headline')}</h1>
        <p className="text-blue-800 text-xl inline-block mb-20">{t('language_button.subline')}</p>

        {availableLanguages.map((key,i) => {
          return <div
            onClick={() => handleLanguageClick(key)}
            key={i}
            data-testid={`language-button-${key}`}
            className="flex items-center bg-white rounded shadow mb-12 text-blue-800 px-8 py-8 text-xl cursor-pointer"
            >
            <div className="w-16 h-16 mr-16">
              {currentLanguage === key && <ReactSVG beforeInjection={(svg) => { svg.classList.add('w-full','w-16','h-16') }} src={iconCheckGreen} /> }
            </div>
            <div>
              {t(`language_button.${key}`)}
            </div>
          </div>
        })}
      </div>
    </div>

    <span
      className="
        absolute sm:top-0 right-0 z-20
        cursor-pointer
        border
        text-blue-800 border-blue-800
        active:text-white active:bg-blue-800
        rounded
        w-16 h-16 sm:w-24 sm:h-24 flex items-center justify-between flex-col
        p-0"
      onClick={handleLanguageOverlayClick}>
      <i className="icon-language mt-2 text-lg sm:text-2xl"></i>
      <div className="text-sm sm:text-base font-bold" data-testid="language-button">{currentLanguage.toUpperCase()}</div>
    </span>
  </div>
}

export default LanguageButton
