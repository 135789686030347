import React from 'react'

const FullscreenStatus = (props) => {
  return <div className={`fixed top-0 left-0 right-0 bottom-0 z-20 px-10 flex flex-col items-center justify-center text-center ${props.classes}`}>

    <p className="text-lg sm:text-2xl text-blue-800 font-bold" dangerouslySetInnerHTML={{__html: props.text1}}></p>

    {props.icon === "spinner" ?
      <i className="loading-icon loading-icon--xl border-blue-800 mt-16"></i> :
      <img src={props.icon} alt="" className="mt-5 mb-8 sm:mb-16" />
    }

    <p className="text-lg text-blue-800" dangerouslySetInnerHTML={{__html: props.text2}}></p>

  </div>
}

export default FullscreenStatus
