import React, {useEffect} from 'react'
import ClientOAuth2 from 'client-oauth2'
import {setCookie, getCookie} from '../tools/cookie.js'
import FullscreenSpinner from './FullscreenSpinner'

const GetConfigAccessToken = (props) => {
  const auth = new ClientOAuth2({
    clientId: props.clientId,
    accessTokenUri: `${props.apiProtocol}${props.apiUrl}/oauth/token`,
    clientSecret: props.oAuthClientSecret,
    scopes: ['kiosk'],
    headers: {
      "X-Sensorberg-Kiosk-Client-Token": props.kioskClientToken,
    }
  })

  useEffect(() => {
    if (!getCookie('configBearerToken')) {
      auth.credentials.getToken()
        .then((data) => {
          setCookie('configBearerToken',JSON.stringify(data.data), data.data.expires_in)
          console.log("%c[CONFIG ACCESS TOKEN] Got config token " + getCookie('configBearerToken'), 'background-color: #1ab26a; color: white')
          props.onChangeComponentState("getconfig")
        })
        .catch((error) => {
          console.error("Error when getting Kiosk Config Access Token")
          console.error(error)
        })
    } else {
      console.log("%c[CONFIG ACCESS TOKEN] Use existing config access token " + getCookie('configBearerToken'), 'background-color: #1ab26a; color: white')
      props.onChangeComponentState("getconfig")
    }
  }, [auth.credentials, props])

  return <FullscreenSpinner />
}

export default GetConfigAccessToken
