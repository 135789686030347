import { gql } from '@apollo/client'

const QueryData = gql`
  query lockers($parentId: [String!]) {
    viewer {
      unitBookings(states: ACTIVE, onActuator: true, parentUnitIds: $parentId, allowedActingDevices: ["identification_code", "nfc_card"]) {
        nodes {
          actuator {
            ... on SensorbergLock {
              id
              name
            }
          }
          id
          externalIdentifier
          unit {
            name
          }
        }
      }
    }
  }
`
export default QueryData
