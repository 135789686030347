import React from 'react'
import { useTranslation } from 'react-i18next'

const Setup = (props) => {
  const { t } = useTranslation()

  const handleSubmit = (event) => {
    event.preventDefault()
    const data = new FormData(event.target)

    fetch(data.get("kiosk_url"), {method: "POST"})
      .then(function (response) {
        response.status === 404 && alert('Already activated or not existing.')
        response.ok === false && console.error(`HTTP Error when setting up Kiosk: ${response.status}: \n${response.statusText}`)

        if (response.ok === true) {
          props.secureLocalStorage.set('sensorbergOauthClientId', response.headers.get('X-Sensorberg-Oauth-Client-Id'))
          props.secureLocalStorage.set('sensorbergKioskClientToken', response.headers.get('X-Sensorberg-Kiosk-Client-Token'))
          props.secureLocalStorage.set('sensorbergOauthClientSecret', response.headers.get('X-Sensorberg-Oauth-Client-Secret'))
          props.secureLocalStorage.set('sensorbergApiUrl', response.headers.get('X-Sensorberg-Api-Url'))
          props.secureLocalStorage.set('sensorbergApiProtocol', data.get("kiosk_url").substring(0, 7).toLowerCase() === 'https:/' ? 'https://' : 'http://')
          props.onChangeComponentState("getconfigaccesstoken")
        }
      })
  }

  return <form onSubmit={handleSubmit} className="flex items-center justify-center flex-col">
    <input
      className="bg-white h-24 max-w-md rounded-lg text-2xl py-5 px-10 text-blue-800"
      data-testid="setup-url"
      type="text"
      name="kiosk_url"
      placeholder={t('setup.placeholder')}
    />
  </form>
}

export default Setup
